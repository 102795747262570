import React, { useState, useEffect } from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Login from './components/login/Login';
import LeftNavBar from "./components/nav/LeftNavBar";
import RightPanel from "./components/rightpanel/RightPanel";
import Dashboard from "./sections/dashboard/Dashboard";
import Accounts from "./sections/accounts/Accounts";
import Contracts from "./sections/contracts/Contracts";
import Templates from "./sections/templates/Templates";
import FunctionEditor from './sections/functions/FunctionEditor';
import Integration from "./sections/integration/Integration";
import { GoogleOAuthProvider } from '@react-oauth/google';
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme.jsx'; // The theme you created
import initialProjectsdata from './init/project.json';
import auth from './init/auth.json';
import functions from './init/functions.json';
import networks from './init/networks.json';

import { solidityCompiler } from "./compilers/solc/index";

import { generateContracts, deployContracts, sendTrx } from "./compilers/generateContracts.js";

function App() {

  const [isLoggedIn, setIsLoggedIn] = useState(0);
  const [functionOptions, setFunctionOptions] = useState(functions);
  const [activeItem, setActiveItem] = useState('Dashboard');
  const [curract, setCurract] = useState('...');
  const [initialProjects, setInitialProjects] = useState(initialProjectsdata);
  const [deletebtnshow, setDeleteBtnShow] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [loadComplete, setLoadComplete] = useState(true);
  const [panelIsVisible, setPanelIsVisible] = useState(true);
  const [isNavVisible, setIsNavVisible] = useState(true);
  const [intervalId, setIntervalId] = useState(null);


  const handleNavItemClick = (item) => {
    setActiveItem(item);

  };
  const handleLogout = (status) => {
    // Begin the logout process
    setIsLoading(true);
    setIsLoggedIn(false);
    setCurract("Logging out...");
    console.log('1logout');
    // Perform the logout using fetch
    fetch(`${apiUrl}/session/logout`, {
      method: 'GET', // Specify the request method
      headers: {
        'Content-Type': 'application/json', // Specify JSON content type
        'Authorization': `Bearer ${localStorage.getItem('login')}` // Use localStorage.getItem for safer access
      }
    })
      .then(response => {

        if (!response.ok) {
          // If the response is not ok, you might want to handle this differently
          throw new Error('Network response was not ok');
        }
        // Logout was successful
        if (localStorage.getItem('login')) {
          localStorage.removeItem('login'); // Correctly use localStorage.removeItem
        }
        setIsLoading(false);
        setCurract("...");
      })
      .catch(error => {

        console.log('elogout');
        // Handle any errors during logout
        console.error('Error:', error);
        // Optionally, set loading to false and update status even in case of error
        setIsLoading(false);
        setCurract("...");
      });
  };

  const getfn = async () => {

    fetch(`https://api.adappter.xyz/platform/fn/retrieve`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.login}`
      }
    }).then(response => {

      return response.json();
    })

      .then(data => {
        console.log(data);
        localStorage.codeBlock = JSON.stringify(data)
      })


  }
  const gettemplates = async () => {

    fetch(`https://api.adappter.xyz/platform/template/retrieve`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.login}`
      }
    }).then(response => {

      return response.json();
    })

      .then(data => {
        console.log(data);
        console.log(initialProjects);
        localStorage.projects = JSON.stringify(data.info)
        setInitialProjects(data.info);
       
      })


  }
  const verifySession = async () => {

    if (localStorage.login) {
      // Check session expiry
      fetch(`${apiUrl}/session/init`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.login}`
        }
      })
        .then(response => {
          console.log('verifysess');
          if (!response.ok) {
            throw new Error('Session validation failed');
          }
          return response.json(); // Assuming the server responds with JSON
        })
        .then(data => {
          console.log(data); setIsLoggedIn(true);
          return true;
          // If success, load profile data




        })
        .catch(error => {
          console.error('Error:', error);
          setIsLoggedIn(false);
        });
    } else {
      setIsLoggedIn(false);
    }
  }

  const handleLogin = (auth, status) => {
    // Begin loading process
    setIsLoading(true);
    console.log(apiUrl, auth);

    // Perform the login using fetch
    fetch(`${apiUrl}/session/login`, {
      method: 'POST', // Specify the request method
      headers: {
        'accept': 'application/json',
        'Content-Type': 'application/json' // Specify JSON content type
      },
      body: JSON.stringify(auth) // Convert the `auth` object to a JSON string
    })
      .then(response => {
        console.log(response);
        if (!response.ok) {
          // If the response is not ok, throw an error
          throw new Error('Network response was not ok');
        }
        return response.json(); // Parse the JSON in the response
      })
      .then(data => {
        // Handle the successful response here
        if (data.status == 'success') {
          console.log('Response:', data);
          setCurract("Logging in...");
          localStorage.setItem('login', data.info.AuthStr); // Store the login token
          getfn();
          gettemplates();
        }
        // Set a timeout to simulate loading
        setTimeout(() => {
          setIsLoading(false);
          setCurract("...");
          setIsLoggedIn(true); // Update the login status
        }, 1500);
      })
      .catch(error => {
        // Handle any errors that occur during the fetch
        console.error('Error:', error);
        setCurract("Credential not recognized");
        setTimeout(() => {
          setIsLoading(false);
          setCurract("...");
        }, 1500);
      });
  };


  const deployTemplate = async (templateData) => {
    // Implement your deployTemplate logic here
    console.log(templateData);
    templateData.githubRepo = ".."; templateData.id = initialProjects.length + 2;
    // loop array, convert each member to an obj: templateData.functions

    console.log(templateData);
    const resp = await talknojutsu(["Generating Contract Files...", "Creating new project folder to Github...", "Deploying service worker to Cloudflare...", "Template successfully generated and deployed."], templateData);
    templateData.githubRepo = resp.info.git;
    templateData.worker = resp.info.worker;
    console.log(resp.info.worker);


  };
  const updatesecret = async (templatename) => {
    console.log('TemplateName:', templatename);
    try {
        const response = await fetch('https://api.adappter.xyz/platform/template/update', {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('login')}`
            },
            body: JSON.stringify({ templateid: templatename })
        });

        const data = await response.json(); // Parse JSON response
        console.log('Response Data:', data);
        
        if (response.ok) {
            return data.secret; // Return the secret if response is OK
        } else {
            throw new Error(`API Error: ${data.message || 'Failed to update secret'}`);
        }
    } catch (error) {
        console.error('Error updating the API secret:', error);
        throw error; // Rethrow the error to be handled by the caller
    }
};
  const sendRequest = async (intervalId, attempts = 0, templateData) => {
    await fetch(templateData.worker, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(response => {
        if (response.ok) {
          console.log('Success: Request succeeded.');
          stopChecking();
          updatesecret(templateData.templateName);
          // Clear the interval if the request is successful
        } else {
          console.log(`Attempt ${attempts + 1} on url: ${templateData.worker}: Request failed, status: ${response.status}`);
        }
      })
      .catch(error => {
        console.error('Error during fetch:', error);
      });
  }
  const apiUrl = 'https://api.adappter.xyz/platform';


  const talknojutsu = async (cstatus, templateData) => {
    setIsLoading(true);
    let fnObj = {};
    const contractObj = await generateContracts(templateData);

    templateData.contract = await contractObj;
    for (const key of templateData.functions) {
      var type = templateData.contract[templateData.networks[0]].abi.find(item => item.name === key.toLowerCase())?.inputs;
      var arg = []; console.log(type);
      for (let i of type) { arg.push(i.name + "/" + i.type); }
      fnObj[key] = arg;
    }
    templateData.functions = fnObj;

    try {
      const response = await fetch(`${apiUrl}/template/generate`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('login')}` // Safer access
        },
        body: JSON.stringify(templateData)
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      console.log('Response:', data);
      handleStatusUpdates(cstatus);
      //
      startChecking(templateData);




      return data;
    } catch (error) {
      console.error('Error:', error);
      setIsLoading(false); // Ensure loading is turned off on error
    }
  };
  const startChecking = (templateData) => {
    var attempts = 0;
    const id = setInterval(() => {
      if (attempts < 4) {
        console.log("attempting..." + attempts);
        sendRequest('checks', attempts, templateData);

        attempts++;
      } else {
        console.log(attempts + ' /Maximum attempts reached, stopping retries, update secret anyway.');
        updatesecret(templateData.templateName);
        stopChecking();
      }
    }, 15000);

    setIntervalId(id);
    console.log('start', id);
  }
  const stopChecking = () => {
    console.log("kill " + intervalId);
    clearInterval(intervalId);
    setIntervalId(null);
  }

  function handleStatusUpdates(cstatus) {
    let count = 0;
    const updateMessage = () => {
      setTimeout(() => {
        setCurract(cstatus[count]);
        if (count >= cstatus.length - 1) {
          setIsLoading(false);
          setLoadComplete(true);
          return;
        }
        count++;
        updateMessage();
      }, 2000);
    };

    updateMessage();
  }


  useEffect(() => {
    verifySession(); // Call verifySession when the component mounts
    getfn();
    gettemplates();

    return () => {
      if (intervalId) {
        console.log("Cleanup interval: " + intervalId);
        clearInterval(intervalId);
      }
    };
  }, [intervalId]);

  return (
    <ThemeProvider theme={theme}>
      <GoogleOAuthProvider clientId="664954214187-tv7ieppgf2r3trq3nq64dnoni98qaiah.apps.googleusercontent.com">
        <div className="App">
          {isLoading &&
            <div class="load-bg">
              <div className={`loading-container ${loadComplete ? '' : 'complete'}`}>
                <div class="loading-circle"></div>
                <div class="loading-circle"></div>
                <div class="loading-circle"></div>
                <div class="loading-circle"></div>
                <div class="loading-text">
                  <h3>Please wait... </h3>
                </div>
              </div>
              <h3><sub>{curract}</sub></h3>
            </div>

          }



          {isLoggedIn ? (<div><LeftNavBar activeItem={activeItem} const isNavVisible={isNavVisible} setIsNavVisible={setIsNavVisible} handleNavItemClick={handleNavItemClick} />
            <div className={`${panelIsVisible ? 'reduced' : 'fullview'} ${isNavVisible ? 'shownav' : 'hidenav'}`} >


              {activeItem === 'Dashboard' && <div>
                <Dashboard activeItem={activeItem} setActiveItem={setActiveItem} />
              </div>}

              {activeItem === 'Config' && <div>
                <Integration functionOptions={functionOptions} talknojutsu={talknojutsu} setFunctionOptions={setFunctionOptions} />
              </div>}
              {activeItem === 'Functions' && <div>
                <FunctionEditor functionOptions={functionOptions} setFunctionOptions={setFunctionOptions} />
              </div>}
              {activeItem === 'Template' && <div>

                <Templates initialProjects={initialProjects} updatesecret={updatesecret} setInitialProjects={setInitialProjects} deployTemplate={deployTemplate} functionOptions={functionOptions} />
              </div>}

              {activeItem === 'Contracts' && <div>
                <Contracts />
              </div>}
              {activeItem === 'Accounts' && <div>
                <Accounts />
              </div>}



            </div>
            <RightPanel functionOptions={functionOptions} talknojutsu={talknojutsu} templateData={initialProjects} panelIsVisible={panelIsVisible} setPanelIsVisible={setPanelIsVisible} handleLogout={handleLogout} />
          </div>
          ) : ("")
          }
          <Router>
            <Routes>
              <Route path="/explainer" element={<div><h1>Explainer...</h1></div>} />
              {isLoggedIn ? (""
              ) : (
                <Route path="/" element={<Login onLogin={handleLogin} />} />
              )}
            </Routes>
          </Router>
        </div></GoogleOAuthProvider>
    </ThemeProvider>
  );
}

export default App;
