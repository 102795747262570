import React, { useState, useEffect  } from 'react';
import { IconContext } from 'react-icons';
import { Card, CardProps ,CardContent, Typography, Grid } from '@mui/material';

import * as ChainIcons from "@thirdweb-dev/chain-icons";
import Activities from '../../components/activities/Activities';
import UsageCharts from '../../components/usagecharts/UsageCharts';
import * as Icons from 'react-icons/fa';
import './dashboard.css';
import {  FaUser, FaExchangeAlt, FaDollarSign } from 'react-icons/fa';




const Dashboard = () => {
  // Fetch component data from an API or set it manually
  const data = [
    { id: 1, name: 'SCA Deployed', count: 100, icon: FaUser },
    { id: 2, name: 'Transaction', count: 500, icon: FaExchangeAlt },
    { id: 3, name: 'Fee Usage', value: 900.00, currency: "USD", icon: FaDollarSign },
  ];
  const CustomCard = ({ variant, ...props }: CardProps & { variant?: 'invert' | undefined }) => {
    const className = variant === 'invert' ? 'MuiCard-invert' : '';
  
    return (
      <Card className={className} {...props}>
        {props.children}
      </Card>
    );
  };

  const IComponent = ({ value }) => {

    const IconComponent = Icons[value] || ChainIcons[value];


    return (
      <div>
        <IconContext.Provider value={{ size: '1.5em' }}>
          {IconComponent && <IconComponent />}
        </IconContext.Provider>
      </div>
    );
  };



  return (
    <div className="dashboard">

<Grid container spacing={2} maxWidth="lg" style={{"margin":"0 auto"}}>
  {data.map((item) => (
    <Grid item xs={12} sm={6} md={4} key={item.id} style={{"flex-basis": "calc(100% - 32px )"}}>
     <CustomCard variant="invert">
        <CardContent className="invert" >
          {item.icon && (
            <div style={{ textAlign: 'center' }}>
              <item.icon style={{ fontSize: '2rem' }} />
            </div>
          )}
          <div style={{ textAlign: 'center' }}>
            <Typography variant="h5" component="div">
            {item.count ? ` ${item.count}` : `${item.value} ${item.currency}`}
            </Typography>
            <Typography variant="body2" color="text.secondary" style={{color:"#FFF"}}>
            {item.name}
            </Typography>
          </div>
        </CardContent>
      </CustomCard>
    </Grid>
  ))}
</Grid>



<UsageCharts />
<Activities />


    </div>
  );
};

export default Dashboard;
