import React from 'react';
import * as ReactIcons from 'react-icons/gr';
import './APIM.css';
import { Accordion, AccordionSummary, AccordionDetails, List, ListItem, ListItemText, IconButton, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DeleteIcon from '@mui/icons-material/Delete';
import KeyIcon from '@mui/icons-material/Key';

class APIM extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      apiKeys: [],
      newApiKey: '',
      showSecret: false,
      showApiList: true, // New state for showing/hiding API list
    };
  }

  handleGenerateApiKey = () => {
    const generatedApiKey = this.generateRandomApiKey();
    const generatedApiSecret = this.generateRandomApiKey();
    this.setState((prevState) => ({
      apiKeys: [...prevState.apiKeys, { key: generatedApiKey, secret: generatedApiSecret, showSecret: false }],
    }));
  };

  handleToggleSecret = (apiKey) => {
    this.setState((prevState) => ({
      apiKeys: prevState.apiKeys.map((key) =>
        key.key === apiKey.key ? { ...key, showSecret: !key.showSecret } : key
      ),
    }));
  };

  handleDeleteApiKey = (apiKey) => {
    this.setState((prevState) => ({
      apiKeys: prevState.apiKeys.filter((key) => key.key !== apiKey.key),
    }));
  };

  generateRandomApiKey() {
    const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let apiKey = '';

    for (let i = 0; i < 24; i++) {
      apiKey += chars.charAt(Math.floor(Math.random() * chars.length));
    }

    return apiKey;
  }

  render() {
    const { apiKeys, showApiList } = this.state;

    return (
      <div style={{ margin: '20px' }}>
        <Accordion expanded={showApiList} onChange={() => this.setState({ showApiList: !showApiList })}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h6"><KeyIcon/> API Manager</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <IconButton onClick={this.handleGenerateApiKey} color="primary" aria-label="add">
              <AddCircleOutlineIcon />
            </IconButton>
            <List>
              {apiKeys.map((apiKey) => (
                <ListItem key={apiKey.key} secondaryAction={
                  <IconButton edge="end" onClick={() => this.handleDeleteApiKey(apiKey)}>
                    <DeleteIcon />
                  </IconButton>
                }>
                  <ListItemText primary={apiKey.key} secondary={apiKey.showSecret && apiKey.secret} />
                  <IconButton onClick={() => this.handleToggleSecret(apiKey)}>
                    {apiKey.showSecret ? <VisibilityOffIcon /> : <VisibilityIcon />}
                  </IconButton>
                </ListItem>
              ))}
            </List>
          </AccordionDetails>
        </Accordion>
      </div>
    );
  }
}

export default APIM;