import React from 'react';
import { Card, Container, Typography, Grid, Table, TableBody, TableCell, TableHead, TableRow, TextField, TableContainer, Paper } from '@mui/material';
import activities from '../../init/activities.json';
import PowerIcon from '@mui/icons-material/Power';

import AccessTime from '@mui/icons-material/AccessTime'; // For "Time"
import CampaignIcon from '@mui/icons-material/Campaign';


class Activities extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      logs: [],
      searchQuery: '',
    };
  }

  componentDidMount() {
    // Fetch activity log data from an API or set it manually
    const logs = activities;
    this.setState({ logs });
  }

  handleSearchChange = (event) => {
    this.setState({ searchQuery: event.target.value });
  };

  render() {
    const { logs, searchQuery } = this.state;

    // Filter logs based on the search query
    const filteredLogs = logs.filter((log) => {
      const { timestamp, action, apiKey } = log;
      const query = searchQuery.toLowerCase();

      return (
        timestamp.toLowerCase().includes(query) ||
        action.toLowerCase().includes(query) ||
        apiKey.toLowerCase().includes(query)
      );
    });

    return (
      <Container maxWidth="lg">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h4" sx={{ my: 4 }}>
            Activity Logs
          </Typography>
          <TextField
            fullWidth
            label="Search..."
            value={searchQuery}
            onChange={this.handleSearchChange}
            variant="outlined"
            sx={{ mb: 2 }}
          />
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell><AccessTime/> Posted</TableCell>
                  <TableCell><CampaignIcon/> Action</TableCell>
                  <TableCell><PowerIcon/> API Key</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredLogs.map((log) => (
                  <TableRow key={log.id}>
                    <TableCell>{log.timestamp}</TableCell>
                    <TableCell>{log.action}</TableCell>
                    <TableCell>{log.apiKey}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
      </Container>
    );
  }
}

export default Activities;
